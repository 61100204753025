import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for your slice
const initialState = {
    isLoading: true,
    errorState:false,
    errorMsg: "",
    checkinData: null
}

export const fetchCheckinData = createAsyncThunk(
    'opViewReducer/fetchCheckinData',
    async ({ authenticationToken, accountID, startDate, endDate, referrer, assetIds, filter }) => {
      try {
        let json = {};
        if (filter === undefined) {
            json = {
                account_id: accountID,
                start_date: startDate,
                end_date: endDate,
                //asset_ids: assetIds
            }
        } else {
            json = {
                account_id: accountID,
                start_date: startDate,
                end_date: endDate,
                //asset_ids: assetIds,
                name_filter: filter /**TZ-1543 */
            }
        }
        const response = await fetch(`https://${referrer}getChecklistJobsOfAccount`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${authenticationToken}`,
          },
          body: JSON.stringify(json)              
        });
  
        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
        return response.json();
      } catch (error) {
          console.log(error)
        throw new Error('Error fetching analytics data: ' + error.message);
      }
    }
);

export const setOpsViewData = (value) => {
    return {
      type: "opViewReducer/setOpsViewData",
      payload: value,
    }
}



// Create a slice to manage the state
const opViewReducer = createSlice({
    name: 'opViewReducer',
    initialState,
    reducers: {
        // Reducer to handle additional actions related to OpsView        
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchCheckinData.pending, (state) => {
          state.loading = true;
          state.errorStatus = false;
          state.errorMsg = "";
        })
        .addCase(fetchCheckinData.fulfilled, (state, action) => {
          state.loading = false;
          state.errorStatus = false;
          state.errorMsg = "";
          state.checkinData = action.payload;
        })
        .addCase(fetchCheckinData.rejected, (state, action) => {
          state.loading = false;
          state.errorStatus = true;
          state.errorMsg = action.payload;
          state.checkinData = null;
        }) 
    },
});

// Export the reducer
export default opViewReducer.reducer;